.modal {
    display: flex;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
    backdrop-filter: blur(5px);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    min-width: 350px;
    max-width: 500px;
    animation: popUp 0.4s ease-in-out; /* Scale and fade-in effect */
  }
  
  .modal-content p {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 1.2rem;
    color: #333;
    margin: 0;
    line-height: 1.5;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes popUp {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  