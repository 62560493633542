.pitch-slides {
  width: max-content;
  display: grid;
  grid-template-columns: repeat(1, 100%);
  gap: 2vw;
  width: 100%;
  height: 100%;
  background-color: black;
  pointer-events: none;
  position: relative;
}
.pitch-slides-iframe {
  height: 68vh;
  width: 99%;
  background-color: black;
  border: 2px solid #3667b2;
  pointer-events: none;
}
.pitch-slides-share-iframe {
  height: 80vh;
  width: 99%;
  background-color: black;
  border: 2px solid #3667b2;
  pointer-events: none;
}
.pitch-loadingIcon {
  position: relative;
  top: 30vh;
  left: 50vh;
}

@media screen and (max-width: 600px) {
  .pitch-slides-iframe {
    height: 25vh;
    width: 100%;
    background-color: black;
    border: none;
  }
  .pitch-slides-share-iframe {
    height: 30vh;
    width: 100%;
  }
}

.pitch-error-txt {
  color: aliceblue;
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
  justify-self: center;
}
