.pitch-main-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  /* justify-content: center; */
  align-items: center;
  z-index: -2;
}

.pitch-PresentationContainer {
  position: relative;
  width: 960px; /* Same as iframe width */
  height: 569px; /* Same as iframe height */
}

.pitch-GooglePresentation {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  width: 100%;
  max-width: 960px; /* Maximum width of the presentation */
}

.pitch-GooglePresentation iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.pitch-WatermarkOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.pitch-WatermarkOverlay span {
  white-space: nowrap;
}

.pitch-presentationshare-viewing-container {
  height: 88.5vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3vw;
}
.pitch-presentationshare-viewing-side {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 0.5vw;
  flex-basis: 50%;
}
.pitch-presentationshare-viewing-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70vw;
  height: 100vh;
}
.pitch-presentationshare-view-slides {
  transform: translateY(6vh);
  display: flex;
  justify-self: center;
  width: 90%;
  height: 90vh;
  background: transparent;
  overflow-y: auto;
  overflow-x: hidden;
}
.pitch-presentationshareContainer {
  background-color: black;
}

.pitch-presentationshare-view-slides::-webkit-scrollbar {
  width: 13px;
  background-color: white;
  border-radius: 4cqb; /* Adjust as needed */
}
.pitch-presentationshare-view-slides::-webkit-scrollbar-thumb {
  background-color: #3667b2;
  border-radius: 10px;
}

.pitch-presentationshare-view-slides::-webkit-scrollbar-track {
  background-color: transparent;
}

@media screen and (max-width: 600px) {
  /* .main-container{
  
    } */
  .pitch-presentationshare-viewing-container {
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 95vw;
    display: flex;
    flex-direction: column;
  }
  .pitch-presentationshare-viewing-side {
    height: max-content;
    padding: 0;
    justify-content: flex-start;
  }
  .pitch-presentationshare-viewing-center {
    width: 100%;
    height: 62vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 0;
  }
  .pitch-presentationshare-view-slides {
    display: flex;
    justify-self: center;
    width: 100%;
    height: 70vh;
    border-radius: 2cqb;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .pitch-PresentationContainer {
    width: 400px;
    height: 200px;
  }
  .pitch-GooglePresentation {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 960px; /* Maximum width of the presentation */
  }
}
